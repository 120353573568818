import React from 'react'
import { Table, TData, TRow } from '@campgladiator/cgui-core.organisms.table'
import styles from './challenges.module.scss'

const NUMBER_OF_ROWS = 3
const arrayOfNumbers = Array.from(Array(NUMBER_OF_ROWS).keys())

const ChallengesSkeleton = ({
  numberOfColumns = 3,
}: {
  numberOfColumns: number
}) => (
  <>
    <Table.Manual>
      {arrayOfNumbers.map((_, rowIndex) => (
        <TRow key={rowIndex}>
          {React.Children.toArray(
            Array.from(Array(numberOfColumns).keys()).map((_, colIndex) => (
              <TData key={colIndex}>
                <div className={styles.skeleton} />
              </TData>
            )),
          )}
        </TRow>
      ))}
    </Table.Manual>
  </>
)

export default ChallengesSkeleton
