export const objectToQueryString = (object: any, includeSeparator = true) => {
  if (!object) return
  return `${includeSeparator && '?'}${new URLSearchParams(object).toString()}`
}

export const extractFileNameFromURL = (url: string): string => {
  const parsedUrl = new URL(url)
  const pathname = parsedUrl.pathname
  const fileName = pathname.substring(pathname.lastIndexOf('/') + 1)
  return fileName
}
