import { useState, useCallback, useEffect, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  fetchWorkouts,
  deleteWorkout,
} from '@campgladiator/cg-common.api.workouts'
import { ButtonProps } from '@campgladiator/cgui-core.atoms.button'
import { ModalProps } from '@campgladiator/cgui-core.molecules.modal'
import { useAppAccess } from 'app/contexts/app-access'
import { useGrowlContext } from 'app/contexts/growl-context'
import { ApiAutocompleteProps } from 'components/form-ui/api-auto-complete/api-auto-complete'
import { getTrainers } from 'services/api/trainer'
import { checkIfFileExists, deleteVideo } from 'services/api/video'
import { extractFileNameFromURL } from 'utils/url'
import type { WorkoutDTO } from '@campgladiator/cg-common.types.types'

const useViewOnDemandVideos = () => {
  const [selectedTrainer, setSelectedTrainer] = useState<string>('')
  const [onDemandVideos, setOnDemandVideos] = useState<WorkoutDTO[]>([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [toggleModal, setToggleModal] = useState<boolean>(false)
  const [modalDescription, setModalDescription] = useState<string>('')
  const [workoutIdToDelete, setWorkoutIdToDelete] = useState<number>(0)
  const [videoFileToDelete, setVideoFileToDelete] = useState<string>('')
  const [thumbnail, setThumbnail] = useState<string>('')
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const navigate = useNavigate()
  const { authToken } = useAppAccess()
  const { showGrowl } = useGrowlContext()

  const size = 20
  const isSearchApplied = selectedTrainer.trim().length > 0

  const handleOpenDeleteModal = (workout: WorkoutDTO) => {
    const modalDescription = `${workout.trainer.firstName} ${workout.trainer.lastName} - ${workout.title}`
    setModalDescription(modalDescription)
    setToggleModal(true)
    setWorkoutIdToDelete(workout.id)
    setVideoFileToDelete(workout.link)
    setThumbnail(workout.photo)
  }

  const handleCloseModal = () => setToggleModal(false)

  const handleEditWorkout = (event: MouseEvent) => {
    event.preventDefault()
    const workoutId = event.currentTarget.getAttribute('data-workout-id')
    if (workoutId) {
      navigate(`/programs/manage-ondemand-videos/edit/${workoutId}`)
    }
  }

  const searchOnDemandVideos = useCallback(
    async (page: number = 0) => {
      if (isSearchApplied) {
        setIsLoading(true)
        const { content: workouts } = await fetchWorkouts({
          trainerId: selectedTrainer,
          type: 'ONDEMAND',
          page,
          size,
        })

        if (page === 0) {
          setOnDemandVideos(workouts)
        } else {
          setOnDemandVideos((prevVideos) => [...prevVideos, ...workouts])
        }
        setHasMore(workouts.length > 0 && workouts.length === size)
        setIsLoading(false)
      } else {
        setOnDemandVideos([])
        setHasMore(false)
      }
    },
    [isSearchApplied, selectedTrainer],
  )

  const loadMoreWorkouts = () => {
    const nextPage = currentPage + 1
    setCurrentPage(nextPage)
    searchOnDemandVideos(nextPage)
  }

  const handleDeleteWorkout = useCallback(async () => {
    try {
      setIsDeleting(true)
      const fileName = extractFileNameFromURL(videoFileToDelete)
      const isFileStored = await checkIfFileExists(fileName)

      if (isFileStored) {
        await deleteVideo(fileName)
      }
      await deleteWorkout(workoutIdToDelete.toString(), authToken?.token || '')

      setToggleModal(false)
      setIsDeleting(false)
      setCurrentPage(0)
      searchOnDemandVideos(0)
      showGrowl('SUCCESS', 'Workout deleted successfully')
    } catch (error) {
      setIsDeleting(false)
      showGrowl('ERROR', 'Failed to delete workout')
    }
  }, [
    authToken?.token,
    searchOnDemandVideos,
    showGrowl,
    videoFileToDelete,
    workoutIdToDelete,
  ])

  const editButtonProps: ButtonProps = {
    emphasis: 'secondary',
    icon: { name: 'icon-edit', type: 'solid' },
    size: 'large',
    variation: 'text',
    onClick: handleEditWorkout,
    theme: 'trainer',
  }

  const deleteButtonProps: ButtonProps = {
    emphasis: 'secondary',
    icon: { name: 'icon-trash', type: 'solid' },
    size: 'large',
    variation: 'text',
    theme: 'trainer',
  }

  const loadMoreButtonProps: ButtonProps = {
    children: isLoading ? 'Loading...' : 'Load More',
    disabled: isLoading || !hasMore,
    emphasis: 'secondary',
    size: 'large',
    theme: 'trainer',
    onClick: loadMoreWorkouts,
  }

  const pageTitleWithSearchInputAndNewButtonProps = {
    title: 'OnDemand Videos',
    placeholder: '',
    newButtonText: 'New OnDemand Video',
    showSearchInput: false,
    onSearch: () => {},
    onNewButtonClick: () => navigate('/programs/manage-ondemand-videos/create'),
  }

  const trainerSearchAutoCompleteProps: ApiAutocompleteProps = {
    id: 'trainer_search',
    name: 'trainer',
    placeholder: 'Search by Trainer Name',
    getMethod: getTrainers,
    searchKey: 'name',
    renderOptionLabel: (record) => `${record.firstName} ${record.lastName}`,
    onSelect: (record: any) => {
      setSelectedTrainer(record.itemId || '')
    },
    value: selectedTrainer,
  }

  const deleteWorkoutModalProps: ModalProps = {
    theme: 'trainer',
    isVisible: toggleModal,
    title: 'Confirm Deletion:',
    buttonPrimary: {
      children: isDeleting ? 'Deleting...' : 'Delete',
      onClick: handleDeleteWorkout,
      disabled: isDeleting,
    },
    buttonSecondary: {
      children: 'Cancel',
      onClick: handleCloseModal,
      disabled: isDeleting,
    },
    onClose: handleCloseModal,
    children: modalDescription,
  }

  useEffect(() => {
    setCurrentPage(0)
    searchOnDemandVideos(0)
  }, [selectedTrainer, searchOnDemandVideos])

  return {
    deleteButtonProps,
    deleteWorkoutModalProps,
    editButtonProps,
    hasMore,
    isLoading,
    loadMoreButtonProps,
    onDemandVideos,
    pageTitleWithSearchInputAndNewButtonProps,
    thumbnail,
    trainerSearchAutoCompleteProps,
    handleOpenDeleteModal,
  }
}

export default useViewOnDemandVideos
