import { useParams } from 'react-router-dom'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { OnDemandVideoForm } from 'components/module/ondemand-video/ondemand-video-form'
import styles from './create.module.scss'

const CreateOnDemandVideo = () => {
  const { id } = useParams()
  const pageTitle = id ? 'Edit OnDemand Video' : 'Upload OnDemand Video'

  return (
    <Card flat className={styles.container}>
      <header className={styles.header}>
        <Heading className={styles.title} type="h3">
          {pageTitle}
        </Heading>
      </header>

      <OnDemandVideoForm id={id} />
    </Card>
  )
}

export default CreateOnDemandVideo
