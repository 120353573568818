const supportedPhotoTypes = ['image/jpeg', 'image/jpg', 'image/png']
const supportedVideoTypes = ['video/mp4']

const supportedPhotoExtensions = ['.jpg', '.jpeg', '.png']
const supportedVideoExtensions = ['.mp4']

export const deriveContentType = (selectedFile: File, fileURL?: string) => {
  if (fileURL) {
    const lowerCaseFileURL = fileURL.toLowerCase()
    if (
      supportedVideoExtensions.some((ext) => lowerCaseFileURL.includes(ext))
    ) {
      return 'VIDEO'
    } else if (
      supportedPhotoExtensions.some((ext) => lowerCaseFileURL.includes(ext))
    ) {
      return 'PHOTO'
    }
  }

  const selectedFileType = selectedFile?.type as string
  if (supportedPhotoTypes.includes(selectedFileType)) {
    return 'PHOTO'
  } else if (supportedVideoTypes.includes(selectedFileType)) {
    return 'VIDEO'
  }

  return 'UNKNOWN'
}

export const isPhotoOrVideo = (file: File, fileURL?: string) => {
  if (fileURL) {
    return (
      supportedPhotoExtensions.some((ext) =>
        fileURL.toLowerCase().includes(ext),
      ) ||
      supportedVideoExtensions.some((ext) =>
        fileURL.toLowerCase().includes(ext),
      )
    )
  }
  const contentType = deriveContentType(file)
  return contentType === 'PHOTO' || contentType === 'VIDEO'
}
