import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ChallengeManagementProps } from '@campgladiator/cg-common.ui.challenge-management'
import { ButtonProps } from '@campgladiator/cgui-core.atoms.button'
import { useAppAccess } from 'app/contexts/app-access'
import { useFetchUser } from 'app/hooks/useFetchUser'
import { NewTransactionType } from './new-transaction'

const useWithNewTransaction = () => {
  const [newTransactionType, setNewTransactionType] =
    useState<NewTransactionType | null>(NewTransactionType.FreezeFee)
  const [toggleCreateChallengeUserModal, setToggleCreateChallengeUserModal] =
    useState<boolean>(false)

  const navigate = useNavigate()
  const { authToken } = useAppAccess()
  const { id: userId } = useParams()
  const user = useFetchUser(userId || '')
  const { firstName, lastName, imageURL } = user

  const newTransactionTypeButtonEmphasis = 'secondary'
  const freezeFeeButtonVariation =
    newTransactionType === NewTransactionType.FreezeFee ? 'solid' : 'outline'
  const trainerTipButtonVariation =
    newTransactionType === NewTransactionType.TrainerTip ? 'solid' : 'outline'
  const headingText = 'New Transaction'

  const handleNewTransactionTypeButtonClick = (type: NewTransactionType) => {
    setNewTransactionType(type)
  }

  const newTransactionTypeButtonsText: ButtonProps[] = [
    {
      children: NewTransactionType.FreezeFee,
      onClick: () =>
        handleNewTransactionTypeButtonClick(NewTransactionType.FreezeFee),
      emphasis: newTransactionTypeButtonEmphasis,
      variation: freezeFeeButtonVariation,
    },
    {
      children: NewTransactionType.TrainerTip,
      onClick: () =>
        handleNewTransactionTypeButtonClick(NewTransactionType.TrainerTip),
      emphasis: newTransactionTypeButtonEmphasis,
      variation: trainerTipButtonVariation,
    },
    {
      children: 'challenge',
      onClick: () => {
        setToggleCreateChallengeUserModal(true)
      },
      emphasis: newTransactionTypeButtonEmphasis,
      variation: toggleCreateChallengeUserModal ? 'solid' : 'outline',
    },
  ]

  const newTransactionTypeButtons: ButtonProps[] =
    newTransactionTypeButtonsText.map(
      ({ children, emphasis, variation, onClick }) => ({
        children,
        size: 'small',
        variation,
        emphasis,
        buttonType: 'button',
        onClick,
      }),
    )

  const createChallengeUserModalProps: ChallengeManagementProps = {
    token: authToken?.token || '',
    camper: { id: userId || '', firstName, lastName, photo: imageURL },
    onClose: () => {
      setToggleCreateChallengeUserModal(false)
    },
  }

  const handleBackClick = () => navigate(-1)

  return {
    createChallengeUserModalProps,
    headingText,
    newTransactionType,
    newTransactionTypeButtons,
    toggleCreateChallengeUserModal,
    handleBackClick,
  }
}

export default useWithNewTransaction
