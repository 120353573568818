import { useState, useRef, useCallback } from 'react'
import classNames from 'classnames'
import { Checkbox } from '@campgladiator/cgui-core.atoms.checkbox'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { useOuterClick } from 'app/hooks'
import styles from './multi-select-dropdown.module.scss'

export type DropdownItem = {
  disabled?: boolean
  label: string
  value: string
}

export type MultiSelectDropdownProps = {
  className?: string
  disabled?: boolean
  id?: string
  label?: string
  name?: string
  onChange: (selectedOptions: string[]) => void
  options: DropdownItem[]
  value?: string[]
}

const MultiSelectDropdown = ({
  className,
  options,
  value = [],
  disabled,
  name,
  id,
  label,
  onChange,
}: MultiSelectDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleToggle = useCallback(() => {
    if (!disabled) {
      setIsOpen(!isOpen)
    }
  }, [disabled, isOpen])

  const handleOptionClick = useCallback(
    (optionValue: string) => {
      const newValue = value.includes(optionValue)
        ? value.filter((v) => v !== optionValue)
        : [...value, optionValue]
      onChange(newValue)
    },
    [value, onChange],
  )

  useOuterClick(dropdownRef, () => {
    setIsOpen(false)
  })

  return (
    <div
      className={classNames(styles.dropdownContainer, className)}
      ref={dropdownRef}
      id={id}
    >
      <div
        className={classNames(styles.dropdown, { [styles.disabled]: disabled })}
        onClick={handleToggle}
        tabIndex={0}
      >
        {value.length === 0 && label && (
          <Paragraph size="small" weight="book">
            {label}
          </Paragraph>
        )}
        <div className={styles.selectedValues}>
          {value.map((val, index) => (
            <Paragraph
              key={`${index}-${val}`}
              className={styles.selectedValue}
              size="small"
              weight="book"
            >
              {options.find((option) => option.value === val)?.label}
            </Paragraph>
          ))}
        </div>
      </div>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {options.map(({ value: optionValue, label, disabled }, index) => (
            <div
              key={`${index}-${optionValue}`}
              className={classNames(styles.dropdownItem, {
                [styles.selected]: value.includes(optionValue),
                [styles.disabled]: disabled,
              })}
            >
              <Checkbox
                defaultChecked={value.includes(optionValue) || disabled}
                disabled={disabled}
                onClick={() => !disabled && handleOptionClick(optionValue)}
              >
                <Paragraph size="small" weight="book">
                  {label}
                </Paragraph>
              </Checkbox>
            </div>
          ))}
        </div>
      )}
      {name &&
        value.map((val) => (
          <input key={val} type="hidden" name={name} value={val} />
        ))}
    </div>
  )
}

export default MultiSelectDropdown
