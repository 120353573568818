import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  Table,
  TRow,
  THead,
  TData,
} from '@campgladiator/cgui-core.organisms.table'
import { PageTitleWithSearchInputAndNewButton } from 'components/module/shared/page-title-search-input-new-button'
import ChallengesSkeleton from './challenges-skeleton'
import styles from './challenges.module.scss'
import useWithChallenges from './hooks/useWithChallenges'

const Challenges = () => {
  const { challengeTable, isLoading, pageTitleProps } = useWithChallenges()
  return (
    <>
      <PageTitleWithSearchInputAndNewButton
        {...pageTitleProps}
        className={styles.headerView}
      />
      <div className={styles.tableContainer}>
        {isLoading ? (
          <ChallengesSkeleton
            numberOfColumns={challengeTable.tableHeaders.length}
          />
        ) : (
          <Table.Manual>
            <TRow>
              {challengeTable.tableHeaders.map((header) => {
                return (
                  <THead key={header}>
                    <Heading type="h6">{header}</Heading>
                  </THead>
                )
              })}
            </TRow>
            {!challengeTable.tableData.length ? (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    No records found
                  </Paragraph>
                </TData>
              </TRow>
            ) : (
              challengeTable.tableData.map((data, rowIndex) => {
                return (
                  <TRow key={`row-${rowIndex}`}>
                    {data.map((item, colIndex) => {
                      return (
                        <TData key={`col-${rowIndex}-${colIndex}`}>
                          <Paragraph size="small" weight="book">
                            {String(item.value)}
                          </Paragraph>
                        </TData>
                      )
                    })}
                  </TRow>
                )
              })
            )}
          </Table.Manual>
        )}
      </div>
    </>
  )
}

export default Challenges
