import styles from './photo-attachment.module.scss'

type AttachmentPhotoProps = {
  attachment: File
  fileURL?: string
}

const PhotoAttachment = ({ attachment, fileURL }: AttachmentPhotoProps) => {
  const photoUrl = fileURL || URL.createObjectURL(attachment)
  return <img src={photoUrl} alt="attachment" className={styles.photo} />
}

export default PhotoAttachment
