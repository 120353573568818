import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchChallengeProgress } from '@campgladiator/cg-common.api.challenges'
import {
  CalculatedProgress,
  calculateUserProgress,
} from '@campgladiator/cg-common.logic.challenges'
import { ChallengeSummaryProgressDTO } from '@campgladiator/cg-common.types.types'
import { useGrowlContext } from 'app/contexts/growl-context'

type TableData = ChallengeSummaryProgressDTO & Partial<CalculatedProgress>

const CHALLENGE_NAME_HEADER = 'Challenge Name'
const TRAINER_NAME_HEADER = 'Lead Trainer'
const CHECKINS = 'Checkins'
const BEGINNING_MILE_TIME = 'Beginning Mile Time'
const ENDING_MILE_TIME = 'Ending Mile Time'
const BEGINNING_PUSHUPS = 'Beginning Pushups'
const ENDING_PUSHUPS = 'Ending Pushups'
const BEGINNING_SITUPS = 'Beginning Situps'
const ENDING_SITUPS = 'Ending Situps'
const BEGINNING_SQUATS = 'Beginning Squats'
const ENDING_SQUATS = 'Ending Squats'
const BEGINNING_BODY_WEIGHT = 'Beginning Body Weight'
const ENDING_BODY_WEIGHT = 'Ending Body Weight'
const BEGINNING_MUSCLE_MASS = 'Beginning Muscle Mass'
const ENDING_MUSCLE_MASS = 'Ending Muscle Mass'
const BEGINNING_BODY_FAT = 'Beginning Body Fat'
const ENDING_BODY_FAT = 'Ending Body Fat'

const headers = [
  CHALLENGE_NAME_HEADER,
  TRAINER_NAME_HEADER,
  CHECKINS,
  BEGINNING_MILE_TIME,
  ENDING_MILE_TIME,
  BEGINNING_PUSHUPS,
  ENDING_PUSHUPS,
  BEGINNING_SITUPS,
  ENDING_SITUPS,
  BEGINNING_SQUATS,
  ENDING_SQUATS,
  BEGINNING_BODY_WEIGHT,
  ENDING_BODY_WEIGHT,
  BEGINNING_MUSCLE_MASS,
  ENDING_MUSCLE_MASS,
  BEGINNING_BODY_FAT,
  ENDING_BODY_FAT,
]

const headerKeyToMap: Record<
  string,
  keyof TableData | ((data: TableData) => string)
> = {
  [CHALLENGE_NAME_HEADER]: 'name',
  [TRAINER_NAME_HEADER]: (data) =>
    `${data.leadTrainer.firstName} ${data.leadTrainer.lastName}`,
  [CHECKINS]: (data) => `${data.checkins}/${data.goal}`,
  [BEGINNING_MILE_TIME]: (data) =>
    data.beginningMileTime ? data.beginningMileTime : '-',
  [ENDING_MILE_TIME]: (data) =>
    data.endingMileTime ? data.endingMileTime : '-',
  [BEGINNING_PUSHUPS]: (data) =>
    data.beginningPushups ? data.beginningPushups : '-',
  [ENDING_PUSHUPS]: (data) => (data.endingPushups ? data.endingPushups : '-'),
  [BEGINNING_SITUPS]: (data) =>
    data.beginningSitups ? data.beginningSitups : '-',
  [ENDING_SITUPS]: (data) => (data.endingSitups ? data.endingSitups : '-'),
  [BEGINNING_SQUATS]: (data) =>
    data.beginningSquats ? data.beginningSquats : '-',
  [ENDING_SQUATS]: (data) => (data.endingSquats ? data.endingSquats : '-'),
  [BEGINNING_BODY_WEIGHT]: (data) =>
    data.beginningBodyWeight ? data.beginningBodyWeight : '-',
  [ENDING_BODY_WEIGHT]: (data) =>
    data.endingBodyWeight ? data.endingBodyWeight : '-',
  [BEGINNING_MUSCLE_MASS]: (data) =>
    data.beginningMuscleMass ? data.beginningMuscleMass : '-',
  [ENDING_MUSCLE_MASS]: (data) =>
    data.endingMuscleMass ? data.endingMuscleMass : '-',
  [BEGINNING_BODY_FAT]: (data) =>
    data.beginningBodyFat ? data.beginningBodyFat : '-',
  [ENDING_BODY_FAT]: (data) => (data.endingBodyFat ? data.endingBodyFat : '-'),
}

const mapDataToHeaders = (
  data: ChallengeSummaryProgressDTO[],
  headers: string[],
) =>
  data.map((item) => {
    const extendedItem = item.cgFitMetricsList
      ? {
          ...item,
          ...calculateUserProgress(item.cgFitMetricsList),
        }
      : item
    return headers.map((header) => {
      const keyOrFunction = headerKeyToMap[header]
      const value =
        typeof keyOrFunction === 'function'
          ? keyOrFunction(extendedItem)
          : extendedItem[keyOrFunction as keyof ChallengeSummaryProgressDTO]
      return {
        value,
      }
    })
  })

const convertChallengesToTable = (
  challenges: ChallengeSummaryProgressDTO[],
) => {
  return {
    tableHeaders: headers,
    tableData: mapDataToHeaders(challenges, headers),
  }
}

const useWithChallenges = () => {
  const [challenges, setChallenges] = useState<ChallengeSummaryProgressDTO[]>(
    [],
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { id } = useParams()
  const { showGrowl } = useGrowlContext()

  const fetchChallengeProgressData = useCallback(async () => {
    if (id) {
      setIsLoading(true)
      try {
        const userChallenges = await fetchChallengeProgress({ userIds: [id] })
        setChallenges(
          userChallenges[0]
            .challengeSummaryProgressDtos as ChallengeSummaryProgressDTO[],
        )
      } catch (error) {
        showGrowl('ERROR', 'Error fetching challenges')
      } finally {
        setIsLoading(false)
      }
    }
  }, [id, showGrowl])

  useEffect(() => {
    fetchChallengeProgressData()
  }, [id, fetchChallengeProgressData])

  const pageTitleProps = {
    title: 'Challenges',
    placeholder: '',
    showSearchInput: false,
    onSearch: () => {},
    onNewButtonClick: () => {},
  }
  const challengeTable = convertChallengesToTable(challenges)

  return { challengeTable, isLoading, pageTitleProps }
}

export default useWithChallenges
