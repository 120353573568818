import { useEffect } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { initialize } from '@campgladiator/cg-common.config.config'
import { loadProductList } from 'app/redux/features/products-list/products-list-actions'
import { useAppDispatch } from 'app/redux/store'
import { ProtectedLayout } from 'components/layouts/protected-layout'
import CreateAddress from 'pages/addresses/create/create'
import Login from 'pages/login'
import {
  CreateOnDemandVideo,
  ViewOnDemandVideos,
} from 'pages/manage-ondemand-videos'
import { OrgDetailsPage } from 'pages/organization/details'
import { ViewOrganizations } from 'pages/partnerships'
import { PartnershipCreate } from 'pages/partnerships/create'
import { CreateProgram, ProgramDetails, ViewPrograms } from 'pages/programs'
import RegionGroups from 'pages/region-groups'
import { RegionGroupCreate } from 'pages/region-groups/components/create'
import { RegionGroupView } from 'pages/region-groups/components/view'
import Regions from 'pages/regions'
import { LocaleCreate } from 'pages/regions/create'
import { CreateTrainer, TrainerDetails, ViewTrainers } from 'pages/trainer'
import Users from 'pages/users'
import { UserCreate } from 'pages/users/create'
import { Details } from 'pages/users/details'
import { configuration } from './config'
import { RegionView } from './pages/regions/view'

const websiteLoginUrl = '/login'

const App = () => {
  const { apiGatewayUrl, documentsUrl } = configuration
  initialize({ apiGatewayUrl, documentsUrl })

  const dispatch = useAppDispatch()

  const ScrollToTop = () => {
    const { pathname } = useLocation()

    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }, [pathname])

    return null
  }

  useEffect(() => {
    dispatch(loadProductList())
  }, [dispatch])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path={'/login'} element={<Login />} />
        <Route
          path="*"
          element={<ProtectedLayout loginUrl={websiteLoginUrl} />}
        >
          <Route path={'users'}>
            <Route index={true} element={<Users />} />
            <Route path=":id/*" element={<Details />} />
            <Route path="create" element={<UserCreate />} />
          </Route>
          <Route path={'regions'}>
            <Route index={true} element={<Regions />} />
            <Route path="create" element={<LocaleCreate />} />
            <Route path=":id" element={<RegionView />} />
          </Route>
          <Route path={'region-groups'}>
            <Route index={true} element={<RegionGroups />} />
            <Route path="create" element={<RegionGroupCreate />} />
            <Route path=":id" element={<RegionGroupView />} />
          </Route>
          <Route path={'addresses/create'} element={<CreateAddress />} />
          <Route path="programs">
            <Route path="manage-programs">
              <Route index element={<ViewPrograms />} />
              <Route path="create" element={<CreateProgram />} />
              <Route path=":id" element={<ProgramDetails />} />
            </Route>
            <Route path="manage-ondemand-videos">
              <Route index element={<ViewOnDemandVideos />} />
              <Route path="create" element={<CreateOnDemandVideo />} />
              <Route path="edit/:id" element={<CreateOnDemandVideo />} />
            </Route>
          </Route>
          <Route path={'trainers'}>
            <Route index element={<ViewTrainers />} />
            <Route path="create" element={<CreateTrainer />} />
            <Route path=":id" element={<TrainerDetails />} />
          </Route>
          <Route path={'organization'}>
            <Route path=":id" element={<OrgDetailsPage />} />
          </Route>
          <Route path={'partnerships'}>
            <Route index element={<ViewOrganizations />} />
            <Route path="create" element={<PartnershipCreate />} />
            <Route path=":id" element={<OrgDetailsPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
