import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import { ActiveProgramType } from 'app/redux/features/active-program/active-program-reducer'
import { pick } from 'lodash'
import { putProgram } from 'services/api/program'
import { Program } from 'types/program'
import { ScheduleProgramForm } from '../schedule-program-form'
import { programValidationSchema } from '../schedule-program-form/hooks/use-schedule-program-form'
import styles from './edit-program.module.scss'

const EditProgram = ({
  programDetails,
}: {
  programDetails: ActiveProgramType
}) => {
  const [alert, setAlert] = useState({
    open: false,
    title: '',
    text: '',
  })
  const [initialData, setInitialData] = useState<Program | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    if (programDetails?.id) {
      const fields = Object.keys(programValidationSchema.fields)
      const programData = pick(programDetails, [...fields, 'id']) as Program
      const { firstName, lastName } = programDetails.trainerSummary
      setInitialData({
        ...programData,
        endDate: programData.endDate || '',
        trainerName: `${firstName} ${lastName}`,
      })
    }
    //eslint-disable-next-line
  }, [JSON.stringify(programDetails)])

  const handleAlertClose = () => {
    setAlert({
      open: false,
      title: '',
      text: '',
    })
  }

  const handleUpdateProgram = (formData: Program) => {
    return new Promise(async (resolve, reject) => {
      const fields = Object.keys(programValidationSchema.fields)
      const programData = pick(formData, [...fields, 'id']) as Program

      try {
        await putProgram(programData)
        setAlert({
          open: true,
          title: 'SUCCESS',
          text: 'Program updated successfully',
        })
        setTimeout(() => navigate(-1), 1000)

        resolve('done')
      } catch (error: any) {
        setAlert({
          open: true,
          title: 'FAILED',
          text: error,
        })

        reject('failed')
      }
    })
  }

  return (
    <div className={styles.formContainer}>
      <GrowlMessage
        isVisible={alert.open}
        onClick={handleAlertClose}
        title={alert.title}
      >
        {alert.text}
      </GrowlMessage>
      <ScheduleProgramForm
        initialData={initialData}
        onSubmitForm={handleUpdateProgram}
      />
    </div>
  )
}

export default EditProgram
