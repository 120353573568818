import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import styles from './video-attachment.module.scss'

type AttachmentVideoProps = {
  attachment: File
  fileURL?: string
}

const VideoAttachment = ({ attachment, fileURL }: AttachmentVideoProps) => {
  const videoUrl = fileURL || URL.createObjectURL(attachment)

  return (
    <div className={styles.videoWrapper}>
      <div className={styles.videoOverlay}>
        <Icon.Solid name="icon-play" />
      </div>

      <video className={styles.video} src={videoUrl} />
    </div>
  )
}

export default VideoAttachment
