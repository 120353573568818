import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { uploadMedia } from '@campgladiator/cg-common.api.media-service'
import { UploadMediaResponse } from '@campgladiator/cg-common.api.media-service/dist/api'
import {
  createWorkout,
  updateWorkout,
  fetchWorkoutById,
} from '@campgladiator/cg-common.api.workouts'
import { type TagDTO } from '@campgladiator/cg-common.types.types'
import { ButtonProps } from '@campgladiator/cgui-core.atoms.button'
import { useAppAccess } from 'app/contexts/app-access'
import { useGrowlContext } from 'app/contexts/growl-context'
import { type ApiAutocompleteProps } from 'components/form-ui/api-auto-complete/api-auto-complete'
import { deriveContentType } from 'components/form-ui/attachment-field/logic'
import { DropdownItem } from 'components/form-ui/dropdown/dropdown'
import { MediaType } from 'components/form-ui/file-uploader'
import { useWithFileUploader } from 'components/form-ui/file-uploader/use-with-file-uploader'
import { getTags } from 'services/api/tag'
import { getTrainers } from 'services/api/trainer'
import {
  checkIfFileExists,
  deleteVideo,
  getSignedUrl,
  uploadVideo,
} from 'services/api/video'
import { extractFileNameFromURL } from 'utils/url'
import { OnDemandVideoFormProps } from './ondemand-video-form'

const generateFileName = (file: File) => {
  const extension = file.name.split('.').pop() || ''
  const isPhoto = deriveContentType(file) === 'PHOTO'
  const prefix = isPhoto ? 'thumbnail' : 'workout'
  return `${prefix}_${new Date()
    .toISOString()
    .replace(/[:.]/g, '-')}.${extension}`
}

const generateNewFileName = (file: File) => {
  const newFileName = generateFileName(file)
  return new File([file], newFileName, { type: file.type })
}

const getDropdownItems = (tags: TagDTO[], tagType: string) => {
  return tags
    .filter((tag) => tag.tagType === tagType)
    .map((tag: TagDTO) => {
      let label = tag.title
      if (label === 'Cool-Down') {
        label = 'Cool Down'
      }
      return {
        label,
        value: tag.id.toString(),
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
}

const isUploadMediaResponse = (obj: any): obj is UploadMediaResponse => {
  return obj && typeof obj === 'object' && 'url' in obj
}

const useOnDemandVideoForm = ({ id }: OnDemandVideoFormProps) => {
  const [selectedTrainer, setSelectedTrainer] = useState<string>('')
  const [selectedTrainerPhoto, setSelectedTrainerPhoto] = useState<string>('')
  const [selectedTrainerName, setSelectedTrainerName] = useState<string>('')
  const [fetchedTags, setFetchedTags] = useState<TagDTO[]>([])
  const [styleOfWorkoutTags, setStyleOfWorkoutTags] = useState<DropdownItem[]>(
    [],
  )
  const [selectedStyleOfWorkoutTag, setSelectedStyleOfWorkoutTag] =
    useState<string>('')
  const [durationTags, setDurationTags] = useState<DropdownItem[]>([])
  const [selectedDurationTag, setSelectedDurationTag] = useState<string>('')
  const [weightFocusTags, setWeightFocusTags] = useState<DropdownItem[]>([])
  const [selectedWeightFocusTag, setSelectedWeightFocusTag] =
    useState<string>('')
  const [cardioFocusTags, setCardioFocusTags] = useState<DropdownItem[]>([])
  const [selectedCardioFocusTag, setSelectedCardioFocusTag] =
    useState<string>('')
  const [bodyFocusTags, setBodyFocusTags] = useState<DropdownItem[]>([])
  const [selectedBodyFocusTag, setSelectedBodyFocusTag] = useState<string>('')
  const [equipmentTags, setEquipmentTags] = useState<DropdownItem[]>([])
  const [selectedEquipmentTags, setSelectedEquipmentTags] = useState<string[]>(
    [],
  )
  const [defaultEquipmentTags, setDefaultEquipmentTags] = useState<TagDTO[]>([])
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [videoFile, setVideoFile] = useState<File | null>(null)
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [thumbnailImageURL, setThumbnailImageURL] = useState<string>('')
  const [videoURL, setVideoURL] = useState<string>('')
  const [storedVideoURL, setStoredVideoURL] = useState<string>('')
  const [submissionStatus, setSubmissionStatus] = useState<
    'idle' | 'submitting' | 'submitted'
  >('idle')

  const hiddenPhotoInputRef = useRef<HTMLInputElement>(null)
  const hiddenVideoInputRef = useRef<HTMLInputElement>(null)

  const { authToken } = useAppAccess()
  const { showGrowl } = useGrowlContext()
  const navigate = useNavigate()
  const location = useLocation()

  const preselectedTags = useMemo(() => ['mat', 'water', 'towel'], [])

  const isEdit = useMemo(
    () => !!id && location.pathname.includes('edit'),
    [id, location.pathname],
  )

  const isFormValid = useMemo(() => {
    const hasVideo = videoFile || (videoURL && videoURL.trim() !== '')
    const hasThumbnail =
      thumbnailFile || (thumbnailImageURL && thumbnailImageURL.trim() !== '')

    return (
      selectedTrainer &&
      selectedStyleOfWorkoutTag &&
      selectedDurationTag &&
      title &&
      description &&
      hasVideo &&
      hasThumbnail
    )
  }, [
    selectedTrainer,
    selectedStyleOfWorkoutTag,
    selectedDurationTag,
    title,
    description,
    videoFile,
    thumbnailFile,
    thumbnailImageURL,
    videoURL,
  ])

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setIsSubmitting(true)
      setSubmissionStatus('submitting')

      try {
        if (isFormValid) {
          const selectedTags = [
            selectedStyleOfWorkoutTag,
            selectedDurationTag,
            selectedWeightFocusTag,
            selectedCardioFocusTag,
            selectedBodyFocusTag,
            ...selectedEquipmentTags,
          ]

          const tags = [
            ...defaultEquipmentTags,
            ...fetchedTags.filter((tag) =>
              selectedTags.includes(tag.id.toString()),
            ),
          ]

          const uploadTasks = []

          if (!thumbnailImageURL) {
            uploadTasks.push(
              uploadMedia({
                bucketName: 'ondemand',
                file: thumbnailFile as File,
                fileName: thumbnailFile?.name || '',
                fileType: 'IMAGE',
              }),
            )
          } else {
            uploadTasks.push(Promise.resolve(null))
          }

          if (!videoURL) {
            const videoSignedUrl = await getSignedUrl()

            if (storedVideoURL) {
              const fileName = extractFileNameFromURL(storedVideoURL)
              await deleteVideo(fileName)
            }

            uploadTasks.push(uploadVideo(videoFile as File, videoSignedUrl))
          } else {
            uploadTasks.push(Promise.resolve(null))
          }

          const [thumbnailUploadResult, videoUploadResult] = await Promise.all(
            uploadTasks,
          )
          const thumbnailUrl =
            thumbnailImageURL ||
            (isUploadMediaResponse(thumbnailUploadResult)
              ? thumbnailUploadResult.url
              : '')
          const videoUrl = videoURL || videoUploadResult

          const formData = {
            link: videoUrl as string,
            photo: thumbnailUrl,
            trainerId: selectedTrainer,
            trainerPhoto: selectedTrainerPhoto,
            title,
            description,
            featured: true,
            duration: Number(selectedDurationTag),
            type: 'ONDEMAND',
            tags,
            token: authToken?.token || '',
          }

          if (isEdit) {
            await updateWorkout({ ...formData, id: id! })
            showGrowl('SUCCESS', 'Workout updated successfully', false)
          } else {
            await createWorkout(formData)
            showGrowl('SUCCESS', 'Workout created successfully', false)
          }

          setSubmissionStatus('submitted')
          setTimeout(() => {
            setIsSubmitting(false)
            navigate(-1)
          }, 2000)
        }
      } catch (error) {
        showGrowl(
          'FAILED',
          isEdit ? 'Failed to update workout' : 'Failed to create workout',
        )
        setIsSubmitting(false)
        setSubmissionStatus('idle')
      }
    },
    [
      authToken?.token,
      defaultEquipmentTags,
      description,
      fetchedTags,
      id,
      isEdit,
      isFormValid,
      selectedBodyFocusTag,
      selectedCardioFocusTag,
      selectedDurationTag,
      selectedEquipmentTags,
      selectedStyleOfWorkoutTag,
      selectedTrainer,
      selectedTrainerPhoto,
      selectedWeightFocusTag,
      storedVideoURL,
      thumbnailImageURL,
      thumbnailFile,
      videoFile,
      videoURL,
      title,
      navigate,
      showGrowl,
    ],
  )

  const trainerSearchAutoCompleteProps: ApiAutocompleteProps = {
    id: 'trainer',
    name: 'trainer',
    placeholder: 'Trainer',
    getMethod: getTrainers,
    searchKey: 'name',
    renderOptionLabel: (record) => `${record.firstName} ${record.lastName}`,
    onSelect: (record: any) => {
      if (record.item) {
        setSelectedTrainerPhoto(record.item.photo || '')
        setSelectedTrainer(record.itemId || '')
        setSelectedTrainerName(
          `${record.item.firstName} ${record.item.lastName}`,
        )
      } else {
        setSelectedTrainerPhoto('')
        setSelectedTrainer('')
        setSelectedTrainerName('')
      }
    },
    value: selectedTrainer,
    initialSelectedItem: selectedTrainerName,
  }

  const handleFileUploaderClick = (
    mediaType: MediaType,
    event?: React.MouseEvent<HTMLElement>,
  ) => {
    event?.preventDefault()

    if (mediaType === 'PHOTO') {
      hiddenPhotoInputRef.current?.click()
    } else if (mediaType === 'VIDEO') {
      hiddenVideoInputRef.current?.click()
    }
  }

  const handleFileUploaderInputChange = (
    mediaType: MediaType,
    event?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event?.target.files
    if (files && files.length) {
      const file = files[0]
      const newFile = generateNewFileName(file)

      if (event.target) {
        event.target.value = ''
      }

      if (mediaType === 'PHOTO') {
        setThumbnailFile(newFile)
        setThumbnailImageURL('')
      } else if (mediaType === 'VIDEO') {
        setVideoFile(newFile)
        setVideoURL('')
      }
    }
  }

  const handleRemoveFile = (attachment: File, fileURL?: string) => {
    if (deriveContentType(attachment, fileURL) === 'PHOTO') {
      setThumbnailFile(null)
      setThumbnailImageURL('')
    } else if (deriveContentType(attachment, fileURL) === 'VIDEO') {
      setVideoFile(null)
      setVideoURL('')
    }
  }

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) =>
      setter(event.target.value)

  const handleStyleOfWorkoutTagChange = handleInputChange(
    setSelectedStyleOfWorkoutTag,
  )
  const handleDurationTagChange = handleInputChange(setSelectedDurationTag)
  const handleWeightFocusTagChange = handleInputChange(
    setSelectedWeightFocusTag,
  )
  const handleCardioFocusTagChange = handleInputChange(
    setSelectedCardioFocusTag,
  )
  const handleBodyFocusTagChange = handleInputChange(setSelectedBodyFocusTag)

  const handleEquipmentTagChange = (selectedOptions: string[]) => {
    setSelectedEquipmentTags(selectedOptions)
  }
  const handleTitleChange = (value: string) => setTitle(value)

  const handleDescriptionChange = (value: string) => setDescription(value)

  const fileUploaderVideo = useWithFileUploader({
    disabled: isSubmitting,
    fileInputRef: hiddenVideoInputRef,
    onChange: handleFileUploaderInputChange,
    onClick: handleFileUploaderClick,
    text: 'Upload video',
    mediaType: 'VIDEO',
  })

  const fileUploaderThumb = useWithFileUploader({
    disabled: isSubmitting,
    fileInputRef: hiddenPhotoInputRef,
    onChange: handleFileUploaderInputChange,
    onClick: handleFileUploaderClick,
    text: 'Upload video thumbnail',
    mediaType: 'PHOTO',
  })

  const trainerFormProps = {
    formLabel: 'Trainer*',
    formForId: 'trainerId',
    trainerSearchAutoCompleteProps,
  }

  const styleOfWorkoutFormProps = {
    formLabel: 'Style of Workout*',
    formForId: 'styleOfWorkoutId',
    dropdownId: 'styleOfWorkout',
    dropdownLabel: 'Choose a style',
    styleOfWorkoutTags,
    selectedStyleOfWorkoutTag,
    handleStyleOfWorkoutTagChange,
  }

  const durationTagFormProps = {
    formLabel: 'Duration*',
    formForId: 'durationId',
    dropdownId: 'duration',
    dropdownLabel: 'Choose a duration',
    durationTags,
    selectedDurationTag,
    handleDurationTagChange,
  }

  const weightFocusTagFormProps = {
    formLabel: 'Weight Focus',
    formForId: 'weightFocusId',
    dropdownId: 'weightFocus',
    dropdownLabel: 'Select Option',
    weightFocusTags,
    handleWeightFocusTagChange,
    selectedWeightFocusTag,
  }

  const cardioFocusTagFormProps = {
    formLabel: 'Cardio Focus',
    formForId: 'cardioFocusId',
    dropdownId: 'cardioFocus',
    dropdownLabel: 'Select Option',
    cardioFocusTags,
    handleCardioFocusTagChange,
    selectedCardioFocusTag,
  }

  const bodyFocusTagFormProps = {
    formLabel: 'Body Focus',
    formForId: 'bodyFocusId',
    dropdownId: 'bodyFocus',
    dropdownLabel: 'Select Option',
    bodyFocusTags,
    handleBodyFocusTagChange,
    selectedBodyFocusTag,
  }

  const equipmentTagFormProps = {
    formLabel: 'Equipment',
    formForId: 'weightsBodyweightId',
    dropdownId: 'weightsBodyweight',
    dropdownLabel: 'Select Needed Equipment',
    equipmentTags,
    selectedEquipmentTags,
    handleEquipmentTagChange,
  }

  const titleFormProps = {
    formLabel: 'Title*',
    formForId: 'titleId',
    rows: 4,
    textAreaId: 'title',
    textAreaName: 'title',
    textAreaPlaceholder: 'Title',
    handleTitleChange,
    title,
  }

  const descriptionFormProps = {
    formLabel: 'Description*',
    formForId: 'descriptionId',
    rows: 4,
    textAreaId: 'description',
    textAreaName: 'description',
    textAreaPlaceholder: 'Description',
    handleDescriptionChange,
    description,
  }

  const publishButtonText =
    submissionStatus === 'submitted'
      ? 'Published'
      : isSubmitting
      ? 'Publishing...'
      : 'Publish New OnDemand Video'

  const saveButtonText =
    submissionStatus === 'submitted'
      ? 'Saved'
      : isSubmitting
      ? 'Saving...'
      : 'Save'

  const saveNewOnDemandVideoButtonProps: ButtonProps = {
    children: publishButtonText,
    emphasis: 'primary',
    size: 'large',
    theme: 'trainer',
    type: 'submit',
    disabled: !isFormValid || isSubmitting,
  }

  const cancelOnDemandVideoButtonProps: ButtonProps = {
    children: 'cancel',
    emphasis: 'secondary',
    size: 'large',
    theme: 'trainer',
    variation: 'outline',
    onClick: (event) => {
      event.preventDefault()
      navigate(-1)
    },
  }

  const saveOnDemandVideoButtonProps: ButtonProps = {
    children: saveButtonText,
    emphasis: 'primary',
    size: 'large',
    theme: 'trainer',
    type: 'submit',
    disabled: !isFormValid || isSubmitting,
  }

  const fetchTags = useCallback(async () => {
    const tags = await getTags(true)
    setFetchedTags(tags)

    const preselectedTagIds = tags.filter((tag) =>
      preselectedTags.includes(tag.title.toLowerCase()),
    )
    setDefaultEquipmentTags(preselectedTagIds)

    const dropdownStyleItems = getDropdownItems(tags, 'style')
    const dropdownDurationItems = getDropdownItems(tags, 'duration')
    const weightFocusItems = getDropdownItems(tags, 'weight focus')
    const cardioFocusItems = getDropdownItems(tags, 'cardio focus')
    const bodyFocusItems = getDropdownItems(tags, 'body focus')

    const equipmentItems = tags
      .filter((tag) => tag.tagType === 'equipment')
      .map((tag: TagDTO) => ({
        label: tag.title,
        value: tag.id.toString(),
        disabled: preselectedTags.includes(tag.title.toLowerCase()),
      }))

    const activeEquipmentItems = equipmentItems
      .filter((item) => !item.disabled)
      .sort((a, b) => a.label.localeCompare(b.label))

    const inactiveEquipmentItems = equipmentItems
      .filter((item) => item.disabled)
      .sort((a, b) => a.label.localeCompare(b.label))

    const sortedEquipmentItems = [
      ...activeEquipmentItems,
      ...inactiveEquipmentItems,
    ]

    setStyleOfWorkoutTags(dropdownStyleItems)
    setDurationTags(dropdownDurationItems)
    setWeightFocusTags(weightFocusItems)
    setCardioFocusTags(cardioFocusItems)
    setBodyFocusTags(bodyFocusItems)
    setEquipmentTags(sortedEquipmentItems)

    if (!isEdit && dropdownStyleItems.length) {
      const defaultStyleTag = dropdownStyleItems.find(
        (tag) => tag.label === 'Camp',
      )
      const defaultDurationTag = dropdownDurationItems.find(
        (tag) => tag.label.toLowerCase() === '60 minutes',
      )
      if (defaultStyleTag) {
        setSelectedStyleOfWorkoutTag(defaultStyleTag.value)
      }
      if (defaultDurationTag) {
        setSelectedDurationTag(defaultDurationTag.value)
      }
    }
  }, [isEdit, preselectedTags])

  useEffect(() => {
    fetchTags()
  }, [fetchTags])

  const fetchWorkout = useCallback(async () => {
    if (id) {
      const workout = await fetchWorkoutById(Number(id))
      const { trainer, tags, title, description, photo, link } = workout

      const fileName = extractFileNameFromURL(link)
      const isFileStored = await checkIfFileExists(fileName)

      const selectedStyle = getDropdownItems(tags, 'style')
      const selectedDuration = getDropdownItems(tags, 'duration')
      const selectedWeightFocusTag = getDropdownItems(tags, 'weight focus')
      const selectedCardioFocusTag = getDropdownItems(tags, 'cardio focus')
      const selectedBodyFocusTag = getDropdownItems(tags, 'body focus')
      let selectedEquipmentTag = getDropdownItems(tags, 'equipment')
      selectedEquipmentTag = selectedEquipmentTag.filter(
        (tag) => !preselectedTags.includes(tag.label.toLowerCase()),
      )

      setSelectedTrainer(trainer.id.toString())
      setSelectedTrainerPhoto(trainer.photo || '')
      setSelectedTrainerName(`${trainer.firstName} ${trainer.lastName}`)

      setSelectedStyleOfWorkoutTag(selectedStyle[0]?.value || '')
      setSelectedDurationTag(selectedDuration[0]?.value || '')
      setSelectedWeightFocusTag(selectedWeightFocusTag[0]?.value || '')
      setSelectedCardioFocusTag(selectedCardioFocusTag[0]?.value || '')
      setSelectedBodyFocusTag(selectedBodyFocusTag[0]?.value || '')
      setSelectedEquipmentTags(selectedEquipmentTag.map((tag) => tag.value))

      setTitle(title)
      setDescription(description)

      setThumbnailImageURL(photo)
      if (isFileStored) {
        setVideoURL(link)
        setStoredVideoURL(link)
      }
    }
  }, [id, preselectedTags])

  useEffect(() => {
    fetchWorkout()
  }, [fetchWorkout])

  return {
    bodyFocusTagFormProps,
    cancelOnDemandVideoButtonProps,
    cardioFocusTagFormProps,
    descriptionFormProps,
    durationTagFormProps,
    equipmentTagFormProps,
    fileUploaderThumb,
    fileUploaderVideo,
    isEdit,
    isSubmitting,
    saveNewOnDemandVideoButtonProps,
    saveOnDemandVideoButtonProps,
    styleOfWorkoutFormProps,
    thumbnailFile,
    thumbnailImageURL,
    titleFormProps,
    trainerFormProps,
    videoFile,
    videoURL,
    weightFocusTagFormProps,
    handleRemoveFile,
    handleSubmit,
  }
}

export default useOnDemandVideoForm
