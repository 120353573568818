import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Divider } from '@campgladiator/cgui-core.atoms.divider'
import { Fieldset } from '@campgladiator/cgui-core.atoms.fieldset'
import { TextArea } from '@campgladiator/cgui-core.atoms.textarea'
import { FormPart } from '@campgladiator/cgui-core.molecules.form-part'
import ApiAutocomplete from 'components/form-ui/api-auto-complete'
import { AttachmentField } from 'components/form-ui/attachment-field'
import { Dropdown } from 'components/form-ui/dropdown'
import { FileUploader } from 'components/form-ui/file-uploader'
import { MultiSelectDropdown } from 'components/form-ui/multi-select-dropdown'
import styles from './ondemand-video-form.module.scss'
import { useOnDemandVideoForm } from '.'

export type OnDemandVideoFormProps = {
  id?: string
}

const OnDemandVideoForm = ({ id }: OnDemandVideoFormProps) => {
  const {
    bodyFocusTagFormProps,
    cancelOnDemandVideoButtonProps,
    cardioFocusTagFormProps,
    descriptionFormProps,
    durationTagFormProps,
    equipmentTagFormProps,
    fileUploaderThumb,
    fileUploaderVideo,
    isEdit,
    isSubmitting,
    saveNewOnDemandVideoButtonProps,
    saveOnDemandVideoButtonProps,
    styleOfWorkoutFormProps,
    thumbnailFile,
    thumbnailImageURL,
    titleFormProps,
    trainerFormProps,
    videoFile,
    videoURL,
    weightFocusTagFormProps,
    handleRemoveFile,
    handleSubmit,
  } = useOnDemandVideoForm({ id })

  const hasVideo = videoFile || videoURL
  const hasThumbnail = thumbnailFile || thumbnailImageURL

  return (
    <form className={styles.formSection} onSubmit={handleSubmit}>
      <FormPart
        label={trainerFormProps.formLabel}
        forId={trainerFormProps.formForId}
      >
        <ApiAutocomplete {...trainerFormProps.trainerSearchAutoCompleteProps} />
      </FormPart>

      <Fieldset className={styles.fieldset}>
        <FormPart
          label={styleOfWorkoutFormProps.formLabel}
          forId={styleOfWorkoutFormProps.formForId}
          className={styles.fieldsetItem}
        >
          <Dropdown
            id={styleOfWorkoutFormProps.dropdownId}
            label={styleOfWorkoutFormProps.dropdownLabel}
            options={styleOfWorkoutFormProps.styleOfWorkoutTags}
            onChange={styleOfWorkoutFormProps.handleStyleOfWorkoutTagChange}
            value={styleOfWorkoutFormProps.selectedStyleOfWorkoutTag}
          />
        </FormPart>
        <FormPart
          label={durationTagFormProps.formLabel}
          forId={durationTagFormProps.formForId}
          className={styles.fieldsetItem}
        >
          <Dropdown
            id={durationTagFormProps.dropdownId}
            label={durationTagFormProps.dropdownLabel}
            options={durationTagFormProps.durationTags}
            onChange={durationTagFormProps.handleDurationTagChange}
            value={durationTagFormProps.selectedDurationTag}
          />
        </FormPart>
      </Fieldset>

      <Divider />

      <Fieldset className={styles.fieldset}>
        <FormPart
          label={weightFocusTagFormProps.formLabel}
          forId={weightFocusTagFormProps.formForId}
          className={styles.fieldsetItem}
        >
          <Dropdown
            id={weightFocusTagFormProps.dropdownId}
            label={weightFocusTagFormProps.dropdownLabel}
            options={weightFocusTagFormProps.weightFocusTags}
            onChange={weightFocusTagFormProps.handleWeightFocusTagChange}
            value={weightFocusTagFormProps.selectedWeightFocusTag}
          />
        </FormPart>
        <FormPart
          label={cardioFocusTagFormProps.formLabel}
          forId={cardioFocusTagFormProps.formForId}
          className={styles.fieldsetItem}
        >
          <Dropdown
            id={cardioFocusTagFormProps.dropdownId}
            label={cardioFocusTagFormProps.dropdownLabel}
            options={cardioFocusTagFormProps.cardioFocusTags}
            onChange={cardioFocusTagFormProps.handleCardioFocusTagChange}
            value={cardioFocusTagFormProps.selectedCardioFocusTag}
          />
        </FormPart>
      </Fieldset>

      <Fieldset className={styles.fieldset}>
        <FormPart
          label={bodyFocusTagFormProps.formLabel}
          forId={bodyFocusTagFormProps.formForId}
          className={styles.fieldsetItem}
        >
          <Dropdown
            id={bodyFocusTagFormProps.dropdownId}
            label={bodyFocusTagFormProps.dropdownLabel}
            options={bodyFocusTagFormProps.bodyFocusTags}
            onChange={bodyFocusTagFormProps.handleBodyFocusTagChange}
            value={bodyFocusTagFormProps.selectedBodyFocusTag}
          />
        </FormPart>
        <FormPart
          label={equipmentTagFormProps.formLabel}
          forId={equipmentTagFormProps.formForId}
          className={styles.fieldsetItem}
        >
          <MultiSelectDropdown
            id={equipmentTagFormProps.dropdownId}
            label={equipmentTagFormProps.dropdownLabel}
            options={equipmentTagFormProps.equipmentTags}
            onChange={equipmentTagFormProps.handleEquipmentTagChange}
            value={equipmentTagFormProps.selectedEquipmentTags}
          />
        </FormPart>
      </Fieldset>

      <FormPart
        label={titleFormProps.formLabel}
        forId={titleFormProps.formForId}
      >
        <TextArea
          rows={titleFormProps.rows}
          id={titleFormProps.textAreaId}
          placeholder={titleFormProps.textAreaPlaceholder}
          name={titleFormProps.textAreaName}
          onTextAreaChange={titleFormProps.handleTitleChange}
          className={styles.textArea}
          value={titleFormProps.title}
        />
      </FormPart>

      <Divider />

      <FormPart
        label={descriptionFormProps.formLabel}
        forId={descriptionFormProps.formForId}
      >
        <TextArea
          rows={descriptionFormProps.rows}
          id={descriptionFormProps.textAreaId}
          placeholder={descriptionFormProps.textAreaPlaceholder}
          name={descriptionFormProps.textAreaName}
          className={styles.textArea}
          onTextAreaChange={descriptionFormProps.handleDescriptionChange}
          value={descriptionFormProps.description}
        />
      </FormPart>

      <div className={styles.fileUploader}>
        <FileUploader {...fileUploaderVideo} />
        {hasVideo && (
          <AttachmentField
            attachment={videoFile as File}
            fileURL={videoURL}
            handleRemoveAttachment={handleRemoveFile}
            disabled={isSubmitting}
          />
        )}
        <FileUploader {...fileUploaderThumb} />
        {hasThumbnail && (
          <AttachmentField
            attachment={thumbnailFile as File}
            fileURL={thumbnailImageURL}
            handleRemoveAttachment={handleRemoveFile}
            disabled={isSubmitting}
          />
        )}
      </div>

      <div className={styles.buttons}>
        {isEdit ? (
          <div className={styles.editButtons}>
            <Button {...cancelOnDemandVideoButtonProps} />
            <Button {...saveOnDemandVideoButtonProps} />
          </div>
        ) : (
          <Button {...saveNewOnDemandVideoButtonProps} />
        )}
      </div>
    </form>
  )
}

export default OnDemandVideoForm
