import styles from './attachment-field.module.scss'
import { AttachmentWrapper } from './components/attachment-wrapper'
import { PhotoAttachment } from './components/photo-attachment'
import { VideoAttachment } from './components/video-attachment'
import { deriveContentType, isPhotoOrVideo } from './logic'

type AttachmentFieldProps = {
  attachment: File
  disabled?: boolean
  fileURL?: string
  handleRemoveAttachment?: (attachment: File, fileURL?: string) => void
}

const AttachmentField = ({
  attachment,
  disabled,
  fileURL,
  handleRemoveAttachment,
}: AttachmentFieldProps) => (
  <div className={styles.wrapper}>
    <div className={styles.attachmentField}>
      {isPhotoOrVideo(attachment, fileURL) && (
        <AttachmentWrapper
          handleRemoveAttachment={() =>
            handleRemoveAttachment &&
            handleRemoveAttachment(attachment, fileURL)
          }
          disabled={disabled}
        >
          {deriveContentType(attachment, fileURL) === 'PHOTO' && (
            <PhotoAttachment attachment={attachment} fileURL={fileURL} />
          )}
          {deriveContentType(attachment, fileURL) === 'VIDEO' && (
            <VideoAttachment attachment={attachment} fileURL={fileURL} />
          )}
        </AttachmentWrapper>
      )}
    </div>
  </div>
)

export default AttachmentField
