import { HttpMethod } from 'app/networking/fetch'
import { configuration } from 'config'

const { camperWebsiteUrl, originRequestUrl } = configuration

export const getSignedUrl = async () => {
  try {
    const endpoint = '/api/get-signed-url'
    const method = 'GET' as HttpMethod

    const response = await fetch(`${camperWebsiteUrl}${endpoint}`, {
      method,
      headers: {
        Origin: originRequestUrl,
      },
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const { url } = await response.json()
    return url
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    throw new Error(errorMessage as string)
  }
}

export const uploadVideo = async (file: File, url: string) => {
  const method = 'PUT'

  try {
    const response = await fetch(url, {
      method,
      body: file,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })

    if (!response.ok) {
      throw new Error(
        `Upload failed with status ${response.status} and status text ${response.statusText}`,
      )
    }

    const urlBeforeExtension = url.replace(/(\.mp4).*/, '$1')

    return urlBeforeExtension
  } catch (error) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    throw new Error(errorMessage as string)
  }
}

export const deleteVideo = async (fileName: string) => {
  try {
    const endpoint = `/api/delete-file?fileName=${encodeURIComponent(fileName)}`
    const method = 'POST' as HttpMethod

    const response = await fetch(`${camperWebsiteUrl}${endpoint}`, {
      method,
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    throw new Error(errorMessage as string)
  }
}

export const checkIfFileExists = async (fileName: string): Promise<boolean> => {
  try {
    const endpoint = `/api/check-file?fileName=${encodeURIComponent(fileName)}`
    const method = 'GET' as HttpMethod

    const response = await fetch(`${camperWebsiteUrl}${endpoint}`, {
      method,
      headers: {
        Origin: originRequestUrl,
      },
    })

    if (!response.ok) {
      if (response.status === 404) {
        return false
      }
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const result = await response.json()
    return result.exists
  } catch (error: any) {
    let errorMessage = error instanceof Error ? error.message : error
    errorMessage = errorMessage || 'An unknown error occurred'

    throw new Error(errorMessage as string)
  }
}
