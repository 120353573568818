import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Loader } from '@campgladiator/cgui-core.atoms.loader'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { Modal } from '@campgladiator/cgui-core.molecules.modal'
import {
  Table,
  TRow,
  TData,
  THead,
} from '@campgladiator/cgui-core.organisms.table'
import ApiAutocomplete from 'components/form-ui/api-auto-complete'
import { PageTitleWithSearchInputAndNewButton } from 'components/module/shared/page-title-search-input-new-button'
import styles from './view.module.scss'
import { useViewOnDemandVideos } from '.'

const ViewOnDemandVideos = () => {
  const {
    deleteButtonProps,
    deleteWorkoutModalProps,
    editButtonProps,
    hasMore,
    isLoading,
    loadMoreButtonProps,
    onDemandVideos,
    pageTitleWithSearchInputAndNewButtonProps,
    thumbnail,
    trainerSearchAutoCompleteProps,
    handleOpenDeleteModal,
  } = useViewOnDemandVideos()
  const { onClick: handleEdit } = editButtonProps
  return (
    <div className={styles.page}>
      <PageTitleWithSearchInputAndNewButton
        {...pageTitleWithSearchInputAndNewButtonProps}
      />

      <div className={styles.searchSection}>
        <ApiAutocomplete {...trainerSearchAutoCompleteProps} />
      </div>

      <Modal {...deleteWorkoutModalProps}>
        <div className={styles.modal}>
          <Paragraph
            className={styles.modalDescription}
            weight="bold"
            size="large"
          >
            {deleteWorkoutModalProps.children}
          </Paragraph>
          <img src={thumbnail} alt="video" className={styles.modalImage} />
        </div>
      </Modal>

      <div className={styles.tableWrapper}>
        <Table.Manual>
          <thead>
            <TRow>
              <THead>
                <Heading type="h6">Trainer</Heading>
              </THead>
              <THead>
                <Heading type="h6">Title</Heading>
              </THead>
              <THead>
                <Heading type="h6">Date Added</Heading>
              </THead>
              <THead>
                <Heading type="h6">Edit</Heading>
              </THead>
            </TRow>
          </thead>
          <tbody>
            {!onDemandVideos.length && !isLoading ? (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    No videos to display
                  </Paragraph>
                </TData>
              </TRow>
            ) : (
              onDemandVideos.map((workout) => (
                <TRow key={workout.id}>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {`${workout.trainer.firstName} ${workout.trainer.lastName}`}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {workout.title}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Paragraph size="small" weight="book">
                      {new Date(workout.created).toLocaleDateString()}
                    </Paragraph>
                  </TData>
                  <TData>
                    <Button
                      data-workout-id={workout.id}
                      onClick={handleEdit}
                      {...editButtonProps}
                    >
                      {editButtonProps.children}
                    </Button>
                    <Button
                      onClick={() => handleOpenDeleteModal(workout)}
                      {...deleteButtonProps}
                    >
                      {deleteButtonProps.children}
                    </Button>
                  </TData>
                </TRow>
              ))
            )}
            {isLoading && (
              <TRow>
                <TData>
                  <Paragraph size="small" weight="book">
                    <Loader />
                  </Paragraph>
                </TData>
              </TRow>
            )}
          </tbody>
        </Table.Manual>
        {hasMore && (
          <div className={styles.buttonWrapper}>
            <Button {...loadMoreButtonProps}>
              {loadMoreButtonProps.children}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ViewOnDemandVideos
